
/**
 * This is a config class
 * 
 */
export default class Config {
    /**
     * max value for addons quantity
     */
    static MAX_VAL = 99999;

    /**
     * super admin role id
     */
    static SUPER_ADMIN_ROLE = 2

    /**
     * admin role id
     */
    static ADMIN_ROLE = 1

    /**
     * delivery status map
     */
    static DELIVERY_STATUS = new Map([
      [1, 'Delivery'],
      [0, 'Pick up'],
      [true, 'Delivery'],
      [false, 'Pick up'],
    ]);

    /**
     * order status map
     */
    static ORDER_STATUS = new Map([
      [0, 'Active'],
      [1, 'Completed'],
      [2, 'Delivered'],
      [3, 'Payment failed'],
    ]);

    /**
     * order table column mapping for sorting
     */
    static ORDER_COLUMN_MAPPING = new Map(
      [
        ['id', 'order_id'],
        ['restaurantName', 'restourant_name'],
        ['orderCreationDate', 'order_creation_date'],
        ['orderDeliveryStatus', 'order_deliveryStatus'],
        ['orderDeliverytime', 'order_deliverytime'],
        ['orderPrice', 'order_price'],
        ['orderFee', 'order_fee'],
        ['orderStatus', 'order_status'],
        ['totalOrderPrice', 'total_price'],
      ]
    );

    /**
     * active status id
     */
    static STATUS_ACTIVE = 0

    /**
     * Completed status id
     */
    static STATUS_COMPLETED = 1

    /**
     * Delivered status id
     */
    static STATUS_DELIVERED = 2

    /**
     * Payment failed status id
     */
    static STATUS_PAYMENT_FAILED = 3

    /**
     * All tags id
     */
    static ALL_TAGS_ID = 0
}