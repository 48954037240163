import * as React from "react";
import ActionsComponent from "../components/ActionsComponent"
import RestaurantOptions from './../modals/RestaurantOptions'

import { List, ImageField, Datagrid, TextField, DeleteButton } from 'react-admin';
import './../App.css';
import { useRefresh } from 'react-admin';

/**
 * Custom field for display restaurant status.
 * @param {*} record data retrieved from api that contains restaurant information.
 */
const StatusField = ({ record = {} }) => <span style={{ whiteSpace: 'nowrap' }}>{record.status === true ? 'Active' : 'Inactive'}</span>;

/**
 * Restaurant list class
 * @param {*} props Props that contain attributes related to restaurants
 */
export const Restaurants = (props) => {
    const refresh = useRefresh();
    return(
        <span>
            <List {...props} bulkActionButtons={false} exporter={false} >
                <Datagrid>
                    <ImageField source="logoUrl" label="Restaurant" className="thumbNailView" sortable={false} />
                    <TextField source="name" sortable={false} />
                    <TextField source="address" sortable={false} />
                    <TextField source="phone" sortable={false} />
                    <StatusField source="status" sortable={false} />
                    <ActionsComponent refresh={refresh} label="Actions" {...props} sortable={false} />
                    <DeleteButton confirmTitle={'Delete restaurant'}
                                  confirmContent={'Are you sure you want to delete this restaurant?'}
                                  undoable={false} />
                    <RestaurantOptions label="Options"{...props} sortable={false} />
                </Datagrid>
            </List>
        </span>
    )
};
