import * as React from "react";
import { List, Datagrid, TextField, DeleteButton } from 'react-admin';
import RestaurantMenuActions from "./../components/RestaurantMenuActions";

/**
 * Users list class
 * @param {*} props Props that contain attributes related to users
 */
export const Menus = (props) => (
    <List {...props} bulkActionButtons={false} pagination={false} exporter={false}>
        <Datagrid style={{textAlign:'center'}}>
            <TextField source="menuName" label="Menu" style={{width:'50%', paddingRight:280, textAlign:'center'}}/>
            <TextField source="dishesName" label="Dishes" style={{width:'30%', paddingRight:300, textAlign:'center'}}/>
            <RestaurantMenuActions {...props} sortable={'false'} />
          <DeleteButton confirmTitle={'Delete menu'}
                        confirmContent={'Are you sure you want to delete this menu?'}
                        undoable={false} />
        </Datagrid>
    </List>
);
