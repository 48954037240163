import Config from './Config';

/**
 * Generates random discount code in 12 character length.
 * @returns {string} the code.
 */
export const generateCode = () => {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < 12; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
};

/**
 * Generates text from order records
 * @returns {string} the text.
 */
export const generateTextFromOrderRecords = (records) => {

    var result = "Order number\tCreation date\t\t\tType\t\tTime\tPrice\t\tStatus\n";
    records.map(record => result += "#" + record.id + "\t\t\t\t" + record.orderCreationDate.split('T')[0] + ' ' + record.orderCreationDate.split('T')[1].split('.')[0] + "\t\t" + Config.DELIVERY_STATUS.get(record.orderDeliveryStatus) + "\t" + record.orderDeliverytime.slice(0, -3) + "\t€ " + record.orderPrice.toFixed(2) + "\t\t" + Config.ORDER_STATUS.get(record.orderStatus) + "\n")
    
    return result;
};